import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


declare global {
  interface Window { analytics: any; }
}

const routes: Routes = [
  { path: '', loadChildren: () => import('../app/layout/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'aboutus', loadChildren: () => import('../app/layout/about/about.module').then(m => m.AboutModule) },
  
  { path: 'yoga', loadChildren: () => import('../app/layout/yoga-landing-page/yoga-landing-page.module').then(m => m.YogaLandingPageModule) },
  { path: 'soloprenueurs', loadChildren: () => import('../app/layout/solopreneur/solopreneur.module').then(m => m.SolopreneurModule) },
  { path: 'startups', loadChildren: () => import('../app/layout/startups/startups.module').then(m => m.StartupsModule) },
  { path: 'non-profit', loadChildren: () => import('../app/layout/non-profit/non-profit.module').then(m => m.NonProfitModule) },
  { path: 'education', loadChildren: () => import('../app/layout/education/education.module').then(m => m.EducationModule) },
  { path: 'healthcare', loadChildren: () => import('../app/layout/healthcare/healthcare.module').then(m => m.HealthcareModule) },
  
  { path: 'partnerships', loadChildren: () => import('../app/layout/partnerships/partnerships.module').then(m => m.PartnershipsModule) },
  { path: 'career', loadChildren: () => import('../app/layout/career/career.module').then(m => m.CareerModule) },
  { path: 'contact', loadChildren: () => import('../app/layout/contact/contact.module').then(m => m.ContactModule) },
  { path: 'contact-sales', loadChildren: () => import('../app/layout/contact-sales/contact-sales.module').then(m => m.ContactSalesModule) },
  { path: 'partner', loadChildren: () => import('../app/layout/partner/partner.module').then(m => m.PartnerModule) },
  
  { path: 'pricing', loadChildren: () => import('../app/layout/pick-plan/pick-plan.module').then(m => m.PickPlanModule) },
  { path: 'plans-and-pricing', loadChildren: () => import('../app/layout/pick-plan/pick-plan.module').then(m => m.PickPlanModule) },
  { path: 'price-comparison', loadChildren: () => import('../app/layout/pick-plan-details/pick-plan-details.module').then(m => m.PickPlanDetailsModule) },
  
  { path: 'privacy-policies', loadChildren: () => import('../app/layout/privacy-policies/privacy-policies.module').then(m => m.PrivacyPoliciesModule) },
  { path: 'privacy-policy', loadChildren: () => import('../app/layout/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: 'data-protection-addendum', loadChildren: () => import('../app/layout/data-protection-addendum/data-protection-addendum.module').then(m => m.DataProtectionAddendumModule) },
  { path: 'california-privacy-choices', loadChildren: () => import('../app/layout/california-privacy-choices/california-privacy-choices.module').then(m => m.CaliforniaPrivacyChoicesModule) },
  { path: 'california-supplemental-privacy-notice', loadChildren: () => import('../app/layout/california-supplemental-privacy-notice/california-supplemental-privacy-notice.module').then(m => m.CaliforniaSupplementalPrivacyNoticeModule) },
  { path: 'privacy-notice-for-utobo-users-in-california', loadChildren: () => import('../app/layout/privacy-notice-for-utobo-users-in-california/privacy-notice-for-utobo-users-in-california.module').then(m => m.PrivacyNoticeForUtoboUsersInCaliforniaModule) },
  { path: 'no-spam-policy', loadChildren: () => import('../app/layout/no-spam-policy/no-spam-policy.module').then(m => m.NoSpamPolicyModule) },
  { path: 'terms-conditions', loadChildren: () => import('../app/layout/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) },
  { path: 'eula', loadChildren: () => import('../app/layout/eula/eula-routing.module').then(m => m.EulaRoutingModule) },
  { path: 'creator-terms', loadChildren: () => import('../app/layout/creator-terms/creator-terms.module').then(m => m.CreatorTermsModule) },
  { path: 'affiliate-terms', loadChildren: () => import('../app/layout/affiliate-terms/affiliate-terms.module').then(m => m.AffiliateTermsModule) },
  { path: 'affiliates', loadChildren: () => import('../app/layout/affiliates/affiliates.module').then(m => m.AffiliatesModule) },
  // { path: 'clickbank', loadChildren: () => import('../app/layout/clickbank/clickbank.module').then(m => m.ClickbankModule) },
  // { path: 'clickbank-thankyou', loadChildren: () => import('../app/layout/clickbank-thankyou/clickbank-thankyou.module').then(m => m.ClickbankThankyouModule) },
  // { path: 'jvzoo', loadChildren: () => import('../app/layout/jvzoo/jvzoo.module').then(m => m.JvzooModule) },
  // { path: 'jvzoo-thankyou', loadChildren: () => import('../app/layout/jvzoo-thankyou/jvzoo-thankyou.module').then(m => m.JvzooThankyouModule) },
  { path: 'blog', loadChildren: () => import('../app/layout/blog/blog/blog.module').then(m => m.BlogModule) },
  { path: 'blog-details/:slug', loadChildren: () => import('../app/layout/blog/blog-details/blog-details.module').then(m => m.BlogDetailsModule) },
  { path: 'news', loadChildren: () => import('../app/layout/news/news.module').then(m => m.NewsModule) },
  { path: 'news-details/:slug', loadChildren: () => import('../app/layout/news-detail/news-detail.module').then(m => m.NewsDetailModule) },
  { path: '404', loadChildren: () => import('../app/commonComponents/not-found/not-found.module').then(m => m.NotFoundModule) },
  { path: '**', redirectTo: '/' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  // imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
