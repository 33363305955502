<footer class="footer">
  <div class="main-footer">
    <div class="container ">
      <div class="row">
        <div class="col-12 col-md-9 col-lg-9">
          <h4 class="mt-4 mt-lg-0" style="font-size: 17px;">Quick links</h4>
          <div class="row">
            <div class="col-12 col-md-4">
              <ul>
                <!-- <li><a routerLink="blog-details/about-us-1707368019551" routerLinkActive="active" style="font-size: 17px;">About us</a></li> -->
                <li><a routerLink="/aboutus" routerLinkActive="active" style="font-size: 17px;">About us</a></li>
                <li><a routerLink="/career" routerLinkActive="active" style="font-size: 17px;">Careers</a></li>
                <li><a routerLink="/contact" routerLinkActive="active" style="font-size: 17px;">Contact us</a></li>
                <!-- <li><a href="https://support.utobo.com/knowledge" target="_blank" rel="noreferrer" style="font-size: 17px;">Support center</a></li> -->
                <li><a routerLink="/news" routerLinkActive="active" style="font-size: 17px;">News</a></li>
                <!-- <li><a href="https://online.utobo.com/" target="_blank" rel="noreferrer" style="font-size: 17px;">Online store demo</a></li> -->
                <!-- <li><a href="https://blog.utobo.com/news/" target="_blank" rel="noreferrer">News</a></li>
                  <li><a href="https://blog.utobo.com/events/" target="_blank" rel="noreferrer">Events</a></li> -->
                <li>
                    <!-- <a href="{{redirectionUrl}}/auth/register?type=free-trial" target="_blank" rel="noreferrer" style="font-size: 17px;">Start for free</a> -->
                    <!-- <a href="#!" (click)="onPlanClick();" style="font-size: 17px;">Start for free</a> -->
                    <a routerLink="/contact-sales" routerLinkActive="active" style="font-size: 17px;">
                      Contact Sales
                    </a>
                </li>
                <li>
                  <a href="#!" data-toggle="modal" data-target="#subscribeModal" style="font-size: 17px;" id="subscribeModalButton">
                    Subscribe to newsletters
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-4">
              <ul>
                <li>
                  <!-- <a href="https://blog.utobo.com/" target="_blank" rel="noreferrer">Blog</a> -->
                  <a routerLink="/blog" routerLinkActive="active" style="font-size: 17px;">Blog</a>
                </li>
                <li><a routerLink="/plans-and-pricing" routerLinkActive="active" style="font-size: 17px;">Pricing</a></li>
                <li><a routerLink="/plans-and-pricing" routerLinkActive="active" style="font-size: 17px;">FAQ</a></li>
                <!-- <li><a routerLink="/partner" routerLinkActive="active">Partner with us</a></li> -->
                <!-- <li><a href="/affiliates" style="font-size: 17px;">Become our affiliate</a></li> -->
                <li>
                  <!-- <a href="{{redirectionUrl}}/auth/register?type=free-trial" target="_blank" rel="noreferrer">Start for free</a> -->
                  <a routerLink="/partnerships" routerLinkActive="active" style="font-size: 17px;">Our partners </a>

                  <!--<a href="https://republic.com/utobo" target="_blank" rel="noreferrer">Invest in utobo</a>-->
                  <!-- <a href="https://blog.utobo.com/success-stories/" target="_blank" rel="noreferrer">Success stories</a> -->
                  <!-- <a href="https://elite206.utobo.com/" target="_blank" rel="noreferrer">Elite 206 creators</a> -->
                </li>
                <li>
                  <a href="https://my2024goals.utobo.com/" target="_blank" rel="noreferrer" style="font-size: 17px;">Set your 2024 goals </a>
                </li>
                <li>
                  <a routerLink="/affiliates" routerLinkActive="active" style="font-size: 17px;">Become our affiliate</a>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-4">
              <ul>
                <!-- <li><a href="https://aws.amazon.com/security" target="_blank" rel="noreferrer">Hosting & security</a></li> -->
                <li><a routerLink="/no-spam-policy" routerLinkActive="active" style="font-size: 17px;">Anti spam policy </a></li>
                <li><a routerLink="/privacy-policies" routerLinkActive="active" style="font-size: 17px;">Privacy policies</a></li>
                <li><a routerLink="/terms-conditions" routerLinkActive="active" style="font-size: 17px;">Terms of use</a></li>
                <li><a routerLink="/eula" routerLinkActive="active" style="font-size: 17px;">EULA</a></li>
                <li><a routerLink="/creator-terms" routerLinkActive="active" style="font-size: 17px;">House rules</a></li>
                <li><a routerLink="/affiliate-terms" routerLinkActive="active" style="font-size: 17px;">Affiliate terms</a></li>
                <!-- <li><a routerLink="/affiliates" routerLinkActive="active">Affiliates</a></li>
                  <li><a routerLink="/clickbank" routerLinkActive="active">Clickbank</a></li>
                  <li><a routerLink="/thankyou" routerLinkActive="active">Thankyou</a></li> -->
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3 col-lg-3 px-md-1" style="text-align: right;">
          <h4 style="font-size: 17px;">Contact us</h4>
          <ul>
            <li>
              <a href="tel:+16503197393" style="text-decoration: none;">
                +1 (650) 319-7393
              </a>
            </li>
            <li>
              <a href="mailto: support@utobo.com" style="text-decoration: none;">
                support@utobo.com
              </a>
            </li>
            <li>
              &nbsp;
            </li>
          </ul>

          <h4 style="font-size: 17px;">Follow us on</h4>
          <ul class="social-links" style="float: right;">

            <li style="margin-right: 0px;"><a href="https://www.facebook.com/utoboinc/" target="_blank" rel="noreferrer"><i class="fab fa-facebook-square" aria-hidden="true"></i></a></li>
            <li style="margin-right: 0px;">
              <a href="https://twitter.com/utobians" target="_blank" rel="noreferrer">
                <!-- <i class="fab fa-twitter-square" aria-hidden="true"></i> -->
                <img src="assets/images/x-twitter.svg" style="height: 26px;background: white;border-radius: 3px;padding: 4px;">
              </a>
            </li>
            <li style="margin-right: 0px;"><a href="https://www.linkedin.com/company/utobo" target="_blank" rel="noreferrer"><i class="fab fa-linkedin "></i></a></li>

            <li style="margin-right: 0px;">
              <a href="https://utobo.com/blog" target="_blank" rel="noreferrer" style="font-family: none;">
                <img src="assets/images/utobo-social.png" style="height: 26px;background: white;border-radius: 3px;">
              </a>
            </li>

            <li style="margin-right: 0px;">
              <a href="https://www.instagram.com/utobo_inc/" target="_blank" rel="noreferrer">
                <i class="fab fa-instagram" aria-hidden="true" style="background: white;color: #33475B;border-radius: 3px;font-size: 1.5em;font-weight: 900;padding: 3px;"></i>
              </a>
            </li>

            <li style="margin-right: 0px;"><a href=" https://www.youtube.com/@utoboInc." target="_blank" rel="noreferrer"><i class="fab fa-youtube-square"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bootm" style="padding: 10px 0">
    <p class="m-0 text-center">© {{currentYear}} utobo inc. All rights reserved.</p>
  </div>
  <!-- Button trigger modal -->

  <!-- Modal -->
  <div class="modal fade" id="subscribeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog bd-example-modal-lg" role="document">
      <div class="modal-content">

        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>

          <h5 class="modal-title" id="exampleModalLabel">Join 10,000+ subscribers</h5>
          <p class="mt-3" style="color: #000000;">You'll receive actionable tips, product updates, and special discounts every week. We are committed to helping you achieve your 2024 goals. </p>

            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label>First Name<span class="text-burnt">*</span></label>
                  <input type="text" [(ngModel)]="firstName" placeholder="Enter first name" class="form-control text15 color-grey3">
                  <p style="color: red;" *ngIf="firstNameMSG"> {{firstNameMSG}}</p>
                </div>
                <div class="form-group">
                  <label>Email<span class="text-burnt">*</span></label>
                  <input type="text" [(ngModel)]="email" placeholder="Enter email" class="form-control text15 color-grey3">
                  <p style="color: red;" *ngIf="emailMSG">{{emailMSG}}</p>
                </div>
                <button type="button" (click)="submitButtonClick();" class="bnt btn-primary btn-lg btn-block mt-3">
                  <span>Subscribe</span>
                  <i class="ic-arrow-right ml-2"></i>
                </button>

                <br>
                <p style="color: green;" *ngIf="apiMSG"> {{apiMSG}}</p>

              </div>
            </div>
        </div>

      </div>
    </div>
  </div>

</footer>
