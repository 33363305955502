import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import {AppCookieService} from 'src/app/services/cookie.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  redirectionUrl = environment.redirectionUrl;
  navbarfixed: boolean = false; 

  constructor(private appCookies: AppCookieService) { }
  ngOnInit(): void {
  }

  @HostListener('window:scroll', ['$event']) onScroll() {
    if (window.scrollY > 100) {
      this.navbarfixed = true;
    } else {
      this.navbarfixed = false;
    }
  }

  onPlanClick() {

    const payload = {
      planId:"60e2f4d5f406f7169054945e",
      termId:"60894b5e0a03af416cac83b4"
    };

    if (environment.production) {
      this.appCookies.setValueForDomain('__selected_plan', JSON.stringify(payload), 'utobo.com');
    } else {

      this.appCookies.setValueForDomain('__selected_plan', JSON.stringify(payload), 'utobo.co.in');
      // this.appCookies.setValueForDomain('__selected_plan', JSON.stringify(payload), 'localhost');
    }

    const creatorURL = `${this.redirectionUrl}/auth/register`;
    window.open(creatorURL, '_self');

  }
}
