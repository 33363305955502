import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'utobo';

  checkJVZoo:any;
  isShowHeader = true;
  isShowFooter = true;

  constructor(private activeRouter: Router) {
    this.activeRouter.events.subscribe((res) => {
      if(
        this.activeRouter.url.includes('/yoga') || 
        this.activeRouter.url.includes('/soloprenueurs') || 
        this.activeRouter.url.includes('/startups') || 
        this.activeRouter.url.includes('/non-profit') || 
        this.activeRouter.url.includes('/education') || 
        this.activeRouter.url.includes('/healthcare')) {

        this.isShowFooter = false;
      } else {
        this.isShowFooter = true;
      }
    })
  }
}
