import { environment } from '../../environments/environment';

function createUrl(actionName: string): string {
  return `${environment.baseUrl}${actionName}`;
}

export const appApiResources = {
  corePlans: createUrl('plans'),
  plans: createUrl('plans-html'),
  blog: createUrl('blog'),
  news: createUrl('news'),
  subscribeEmail: createUrl('subscribe-email'),
  checkDomain: createUrl('auth/educator/checkDomain'),
};
