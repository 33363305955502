import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';

import { FooterComponent } from './commonComponents/footer/footer.component';
import { HeaderComponent } from './commonComponents/header/header.component';

import { CookieService, CookieModule } from '@gorniv/ngx-universal';
import { FormsModule } from '@angular/forms';
import { PrivacyPoliciesComponent } from './layout/privacy-policies/privacy-policies.component';
import { DataProtectionAddendumComponent } from './layout/data-protection-addendum/data-protection-addendum.component';
import { CaliforniaPrivacyChoicesComponent } from './layout/california-privacy-choices/california-privacy-choices.component';
import { CaliforniaSupplementalPrivacyNoticeComponent } from './layout/california-supplemental-privacy-notice/california-supplemental-privacy-notice.component';
import { PrivacyNoticeForUtoboUsersInCaliforniaComponent } from './layout/privacy-notice-for-utobo-users-in-california/privacy-notice-for-utobo-users-in-california.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PrivacyPoliciesComponent,
    DataProtectionAddendumComponent,
    CaliforniaPrivacyChoicesComponent,
    CaliforniaSupplementalPrivacyNoticeComponent,
    PrivacyNoticeForUtoboUsersInCaliforniaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CookieModule.forRoot(),
    FormsModule
  ],
  providers: [
    CookieService,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
