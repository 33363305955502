import { Injectable } from '@angular/core';
// import { CookieService } from 'ngx-cookie-service';
import { CookieService } from '@gorniv/ngx-universal';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class AppCookieService {
    constructor(private cookieService: CookieService) { }

    public setValue(key: string, value: string, expires = moment().add(1, 'day').toDate()) {
        this.cookieService.put(key, value, { expires });
    }

    public setValueForDomain(key: string, value: string, domain: string, expires = moment().add(1, 'day').toDate()) {
        this.cookieService.put(key, value, { expires , domain });
    }
    public getValue(key: string) {
        return this.cookieService.get(key);
    }

    public deleteAllCookie() {
        return this.cookieService.removeAll();
    }

    public delete(key: string) {
        return this.cookieService.remove(key);
    }
}
