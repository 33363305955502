<header class="header mt-4" (scroll)="onScroll()" [ngClass]="navbarfixed?'fixed':'notfixed'" style="background-color: white;">

  <div class="container">
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand" routerLink="/" routerLinkActive="active"><img src="assets/images/logo.svg" alt="logo"
          class="customeLogo"></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="fa fa-bars fa-lg"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: black;font-size: 17px;font-weight: 500;padding-top: 6px;cursor: pointer;">
              Solutions
            </a>
              <ul class="dropdown-menu multi-column columns-3">
                <!-- <li><a class="dropdown-item" routerLink="/yoga" style="font-weight: 500;">Yoga and Fitness</a></li> -->
                <div class="row">
                  <div class="col-sm-3">
                    <ul class="multi-column-dropdown" style="padding: 0px;">
                      <li><a style="color: #ff7a59;">Start</a></li>
                      <li><a class="nav-link" href="https://www.utobo.us/products/domain-registration" target="_blank">Domain</a></li>
                      <li><a class="nav-link" href="https://www.utobo.us/products/cpanel" target="_blank">Hosting</a></li>
                      <li><a class="nav-link" href="https://www.utobo.us/products/website-security" target="_blank">Security</a></li>
                      <li><a class="nav-link" href="https://www.utobo.us/products/professional-email" target="_blank">Professional email</a></li>
                    </ul>
                  </div>
                  <div class="col-sm-3" style="padding: 0px;">
                    <ul class="multi-column-dropdown" style="padding: 0px;">
                      <li><a style="color: #ff7a59;">Create</a></li>

                      <!-- Development Test Rout -->
                      <!-- <li><a class="nav-link" routerLink="blog-details/capture-leads-effortlessly-with-utobos-embedded-forms-1706376111458" routerLinkActive="active">Link 1</a></li>
                      <li><a class="nav-link" routerLink="blog-details/unlock-insights--reports-1706376004952" routerLinkActive="active">Link 2</a></li>
                      <li><a class="nav-link" routerLink="blog-details/-online-course--1706032341955" routerLinkActive="active">Link 3</a></li> -->
                      <!-- Development Test Rout -->

                      <li><a class="nav-link" routerLink="blog-details/offer-selfpaced-online-learning-to-your-learners-1706379460124" routerLinkActive="active">Courses</a></li>
                      <li><a class="nav-link" routerLink="blog-details/create-a-subscriptionbased-pricing-for-your-course-1706415335223" routerLinkActive="active">Memberships</a></li>
                      <li><a class="nav-link" routerLink="blog-details/create-and-deploy-cohortbased-courses-in-minutes-1706415429629" routerLinkActive="active">Cohorts</a></li>
                      <li><a class="nav-link" routerLink="blog-details/sell-digital-files-such-as-music-templates-or-ebooks-1706415525756" routerLinkActive="active">Digital Products</a></li>
                      <li><a class="nav-link" routerLink="blog-details/make-your-course-enriching-and-engaging-with-quizzes-1706415238454" routerLinkActive="active">Quiz</a></li>
                    </ul>
                  </div>
                  <div class="col-sm-3" style="padding: 0px;">
                    <ul class="multi-column-dropdown" style="padding: 0px;">
                      <li><a style="color: #ff7a59;">Market</a></li>
                      <li><a class="nav-link" routerLink="blog-details/get-an-online-store-to-sell-your-courses-1706379222411" routerLinkActive="active">Online Store</a></li>
                      <li><a class="nav-link" routerLink="blog-details/show-your-personal-profile-work-experience-education--more-1706378720895" routerLinkActive="active">Creator Page</a></li>
                      <li><a class="nav-link" routerLink="blog-details/craft-and-send-awesome-email-campaigns-1706378517789" routerLinkActive="active">Email Campaigns</a></li>
                      <li><a class="nav-link" routerLink="blog-details/seo-optimized-website-your-online-store-ranks-at-the-top-1706378277563" routerLinkActive="active">SEO</a></li>
                      <li><a class="nav-link" routerLink="blog-details/publish-blogs-and-build-subscribers-1706420084938" routerLinkActive="active">Blog</a></li>
                      <li><a class="nav-link" routerLink="blog-details/boost-your-online-presence-with-social-media-integrations-1706376970650" routerLinkActive="active">Social Media Integrations</a></li>
                    </ul>
                  </div>
                  <div class="col-sm-3" style="padding: 0px;">
                    <ul class="multi-column-dropdown" style="padding: 0px;">
                      <li><a style="color: #ff7a59;">Sell</a></li>
                      <li><a class="nav-link" routerLink="blog-details/streamline-lead-management-with-utobos-mini-crm-1706376852504" routerLinkActive="active">Mini CRM</a></li>
                      <li><a class="nav-link" routerLink="blog-details/capture-leads-effortlessly-with-utobos-embedded-forms-1706376725482" routerLinkActive="active">Lead Capture Forms</a></li>
                      <li><a class="nav-link" routerLink="blog-details/unlock-insights--reports-1706376478492" routerLinkActive="active">Analytics</a></li>
                      <li><a class="nav-link" routerLink="blog-details/integrated-payments--setup-stripe--paypal-1706376393733" routerLinkActive="active">Accept Payments</a></li>
                      <li><a class="nav-link" routerLink="blog-details/efficient-learner--fee-management-with-utobo-1706374255308" routerLinkActive="active">Manage Fee</a></li>
                      <li><a class="nav-link" routerLink="blog-details/track-sales-your-most-loved-courses-emails-and-more-1706357946652" routerLinkActive="active">Reports</a></li>
                    </ul>
                  </div>
                </div>
              </ul>
          </li>
          <li class="dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: black;font-size: 17px;font-weight: 500;padding-top: 6px;cursor: pointer;">
              Best Fit For
            </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" routerLink="/soloprenueurs" style="font-weight: 500;">Solopreneurs</a></li>
                <li><a class="dropdown-item" routerLink="/startups" style="font-weight: 500;">Startups</a></li>
                <li><a class="dropdown-item" routerLink="/education" style="font-weight: 500;">Coaching Centers</a></li>
                <li><a class="dropdown-item" routerLink="/non-profit" style="font-weight: 500;">Nonprofits</a></li>
                <li><a class="dropdown-item" routerLink="/yoga" style="font-weight: 500;">Yoga and Fitness</a></li>
                <li><a class="dropdown-item" routerLink="/healthcare" style="font-weight: 500;">Healthcare Providers</a></li>
              </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/plans-and-pricing" routerLinkActive="active" style="color: black;font-size: 17px;">Pricing</a>
          </li>
          <li class="dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: black;font-size: 17px;font-weight: 500;padding-top: 6px;cursor: pointer;">
              Resources
            </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="https://youtu.be/D_ojIFIirMQ/" target="_blank" style="font-weight: 500;color: black;text-decoration: none;">What is utobo</a></li>
                <li><a class="dropdown-item" href="https://youtu.be/avrizkN_0bU/" target="_blank" style="font-weight: 500;color: black;text-decoration: none;">How it works</a></li>
                <li><a class="dropdown-item" routerLink="/blog" style="font-weight: 500;">Blog</a></li>
                <li><a class="dropdown-item" href="https://support.utobo.com/" target="_blank" style="font-weight: 500;color: black;text-decoration: none;">Support</a></li>
                <li><a class="dropdown-item" routerLink="/contact-sales" style="font-weight: 500;">Contact Sales</a></li>
                <li><a class="dropdown-item" href="https://www.meetup.com/hackerdojo/events/298595026/" target="_blank" style="font-weight: 500;color: black;text-decoration: none;">Creator Accelerator</a></li>
              </ul>
          </li>
         
          <!-- <li class="nav-item">
            <a class="nav-link" routerLink="/partner" routerLinkActive="active">Partner with us</a>
            <a class="nav-link" routerLink="/affiliates" routerLinkActive="active" style="color: black;font-size: 17px;">Become Our Affiliate</a>
            <a class="nav-link" href="https://elite206.utobo.com/" target="_blank">Elite 206 creators</a>
            <a class="nav-link" href="https://my2024goals.utobo.com/" target="_blank" rel="noreferrer" style="font-size: 17px;">Set your 2024 goals </a>
          </li> -->
        </ul>
        <ul class="nav">
          <!-- <li class="nav-item">
            <a routerLink="/contact-sales" routerLinkActive="active" class="nav-link" style="color: black;font-size: 17px;">
              <img src="assets/images/user.svg" class="mr-2">
              Contact Sales
            </a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" (click)="onPlanClick();" routerLinkActive="active" style="color: black;font-size: 17px;cursor: pointer;">Start for free</a>
          </li>
          <li><a href="{{redirectionUrl}}/auth/login" target="_blank" class="btn btn-danger nav-link"
              rel="noreferrer" style="border-radius: 4px;font-size: 16px;margin-top: -3px;">Login</a></li>
        </ul>
      </div>
    </nav>
  </div>
</header>