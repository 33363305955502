import { Component, OnInit,HostListener } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NewsService } from 'src/app/services/news.service';
import { ServerResponse } from 'src/app/services/model.interface';

import {AppCookieService} from 'src/app/services/cookie.service';

declare let $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  redirectionUrl = environment.redirectionUrl;
  currentYear: number;

  firstName = '';
  email = '';
  
  firstNameMSG = '';
  emailMSG = '';
  apiMSG = '';
  newsLetterShow = false;

  constructor(protected serviceNews: NewsService,private appCookies: AppCookieService) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    // setTimeout(() => {
    //   $('#subscribeModal').modal({
    //     backdrop: 'static',
    //     keyboard: false,
    //   })
    // }, 5000);
    
  }

  @HostListener("window:scroll") onWindowScroll() {
    let scroll = window.pageYOffset ||
                 document.documentElement.scrollTop ||
                 document.body.scrollTop || 0;

    const max = document.documentElement.scrollHeight -
                document.documentElement.clientHeight;

    const showPopup = Math.round(scroll * 100 / max);

    if(showPopup > 99 && this.newsLetterShow == false)
    {
      document.getElementById("subscribeModalButton").click();
      $('#subscribeModal').modal({
        backdrop: 'static',
        keyboard: false,
      });
      this.newsLetterShow = true;
    }
  }
  
  submitButtonClick() {

    this.firstNameMSG ='';
    this.emailMSG ='';
    this.apiMSG ='';

    if (this.firstName == '') {

      this.firstNameMSG = 'Please enter your first name.';

    } else if (this.email == '') {

      this.emailMSG = 'Please enter your email address.';

    } else {

      const newsLetterData: { [k: string]: any } = {

        first_name: this.firstName,
        email: this.email,
      };

      this.serviceNews.subscribeNewsLetter(newsLetterData).subscribe((res: ServerResponse) => {

        if (res.code == 200 && res.data != null) {

          const message = res.message;
          this.apiMSG = message;

          this.firstName = '';
          this.email = '';

          setTimeout(() => {
            $("#subscribeModal").modal("hide");
          }, 1000);

        } else {
          this.emailMSG = res.error;
        }
      },
        (err) => {
          
          console.log(err);
          this.apiMSG = err;

        });

    }
  }

  onPlanClick() {

    const payload = {
      planId:"60e2f4d5f406f7169054945e",
      termId:"60894b5e0a03af416cac83b4"
    };

    if (environment.production) {
      this.appCookies.setValueForDomain('__selected_plan', JSON.stringify(payload), 'utobo.com');
    } else {

      this.appCookies.setValueForDomain('__selected_plan', JSON.stringify(payload), 'utobo.co.in');
      // this.appCookies.setValueForDomain('__selected_plan', JSON.stringify(payload), 'localhost');
    }

    const creatorURL = `${this.redirectionUrl}/auth/register`;
    window.open(creatorURL, '_self');

  }
}
