import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { appApiResources } from './api-list';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppCookieService } from './cookie.service';


@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private appCookie: AppCookieService,public baseService: BaseService) { }

  getNews(paginationShort): Observable<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + this.appCookie.getValue('logintoken'));

    return this.baseService.post(appApiResources.news + '/list',paginationShort, headers);
  }

  getNewsDetaills(slug): Observable<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + this.appCookie.getValue('logintoken'));

    return this.baseService.get(appApiResources.news +'/' +slug, headers);
  }

  subscribeNewsLetter(newsLetterData): Observable<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + this.appCookie.getValue('logintoken'));

    return this.baseService.post(appApiResources.subscribeEmail,newsLetterData, headers);
  }
}
