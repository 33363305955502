import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(private http: HttpClient, public router: Router) { }

  postWithoutHeader(url: string, postBody: any) {

    return this.http.post(url, postBody);

  }

  get(url, header: HttpHeaders) {

    return this.http.get(url, { headers: header });
  }

  post(url, postBody: any, header: HttpHeaders) {

    return this.http.post(url, postBody, { headers: header });
  }

  put(url, postBody: any, header: HttpHeaders) {

    return this.http.put(url, postBody, { headers: header });
  }

  delete(url,header: HttpHeaders) {

    return this.http.delete(url, { headers: header });
  }
}
